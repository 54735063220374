<template>
  <div>
    <div class="banner-stock-club">
      <b-container>
        <div v-if="!loading && usuario">
          <b-row>
            <b-col cols="12" lg="3" class="col-carteirinha" data-anima="left">
              <div
                class="carteirinha"
                :class="{ 'tem-piloto': usuario.pilotoFavorito }"
              >
                <h4>{{ usuario.name }}</h4>
                <p>
                  Fã desde
                  {{ anoCadastro(usuario.createdAt) }}
                </p>
                <img
                  v-if="usuario.pilotoFavorito"
                  alt="Imagem de carteirinha do piloto"
                  class="piloto-carteirinha"
                  :src="usuario.pilotoFavorito.imagem"
                />
                <img
                  v-else
                  alt="Imagem de carteirinha do piloto"
                  class="piloto-carteirinha"
                  src="@/assets/img/piloto_default.png"
                />

                <div class="info-piloto" v-if="usuario.pilotoFavorito">
                  <span>#{{ usuario.pilotoFavorito.numero }}</span>
                  <span>{{ usuario.pilotoFavorito.nome }}</span>
                </div>
                <!-- <div class="info-piloto" v-else>
                  <span>###</span>
                  <span>Sem piloto favorito</span>
                </div> -->

                <router-link
                  to="/stockclub/meu-perfil"
                  v-else
                  class="info-piloto default"
                  style="text-decoration: none"
                >
                  <b-button>Escolha o piloto favorito</b-button>
                </router-link>
              </div>
            </b-col>

            <b-col cols="12" lg="4" class="info-etapa">
              <InformacoesEtapas />
            </b-col>

            <b-col cols="12" lg="5" class="btn-banner" data-anima="right">
              <a
                :href="btn_ingresso.link"
                target="_blank"
                class="btn"
                :class="{ 'btn-desativado': !btn_ingresso.show }"
                ><strong>GARANTA SEUS</strong> INGRESSOS</a
              >
              <router-link
                to="/stockclub/votacao-fanpush"
                :class="
                  fanpush && fanpush.fanpushAberto
                    ? 'btn mt-3'
                    : 'btn-desativado'
                "
                ><strong>Vivo Fan Push by Snapdragon</strong></router-link
              >
            </b-col>
          </b-row>
        </div>

        <b-modal
          id="modal-atualizar-cadastro"
          class="modal-cadastro"
          :title="`MODAL CADASTRO`"
          size="lg"
          variant="dark"
          hide-header
          hide-footer
          :no-close-on-backdrop="true"
          :no-close-on-header="true"
        >
          <div>
            <div>
              <b-col cols="12" lg="12" v-if="msgErro" class="msg-erro">
                <h4 class="nome-modal">
                  <strong style="color: #ff1717; text-transform: uppercase">{{
                    usuario.name
                  }}</strong
                  >,
                  {{
                    texto_erro || "OCORREU UM ERRO AO PROCESSAR A REQUISIÇÃO."
                  }}

                  <!-- OCORREU UM ERRO AO PROCESSAR A REQUISIÇÃO. -->
                </h4>
              </b-col>

              <b-col cols="12" lg="12" v-if="msgSucesso" class="msg-sucesso">
                <h4 class="nome-modal">
                  <strong style="color: #f9c14e; text-transform: uppercase">{{
                    usuario.name
                  }}</strong
                  >, SUAS INFORMAÇÕES FORAM ATUALIZADAS.
                </h4>
              </b-col>

              <b-col cols="12" lg="12" v-if="!msgSucesso && !msgErro">
                <h4 class="nome-modal">
                  <strong style="color: #f9c14e; text-transform: uppercase">{{
                    usuario.name
                  }}</strong
                  >, ATUALIZE SUAS INFORMAÇÕES.
                </h4>
                <p class="txt-modal">
                  Complete seu cadastro para continuar a navegar no
                </p>
                <img
                  src="@/assets/img/stock-club.png"
                  alt="Lohgo stock club"
                  class="logostock"
                />
                <div class="linha"></div>
              </b-col>

              <b-form v-if="usuario && !msgSucesso" @submit.prevent="onSubmit">
                <b-row class="dados-cadastro" v-if="!loading_modal">
                  <b-col cols="12" lg="6" class="float-container">
                    <b-form-group id="nome" label-for="nome">
                      <input-with-label
                        id="input-name"
                        label="Nome*"
                        class="form-control nome"
                        name="nome"
                        v-model="usuario.name"
                        input-class="my-input-class"
                        label-class-custom="my-label-class"
                        required
                        :disabled="loading || msgSucesso"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" lg="6">
                    <b-form-select
                      class="select form-control"
                      :value="selectedOption"
                      v-model="usuario.genero"
                      placeholder="Selecione o gênero*"
                      :options="options"
                      required
                      :disabled="loading || msgSucesso"
                    ></b-form-select>
                  </b-col>

                  <b-col cols="12" lg="6">
                    <input-with-label-mask
                      id="cpf"
                      label="CPF*"
                      name="cpf"
                      class="form-control cpf"
                      placeholder="CPF"
                      v-model="usuario.cpf"
                      @keyup.native="validaDocumento"
                      :input-class="{
                        error: errors.cpf,
                      }"
                      :mask="['###.###.###-##']"
                      autocomplete="off"
                      required
                      :disabledMasked="false"
                      :disabled="loading || msgSucesso"
                    ></input-with-label-mask>
                    <span class="error-span" v-if="errors.cpf"
                      >- Número de CPF inválido</span
                    >
                  </b-col>

                  <b-col cols="12" lg="6">
                    <input-with-label-mask
                      class="form-control datanasc"
                      label="Data de nascimento*"
                      id="datanasc"
                      name="datanasc"
                      :value="usuario.dataNascimento | dateFormat"
                      :mask="['##/##/####']"
                      @keyup.native="validaIdade"
                      :input-class="{
                        error: nao_se_cadastra,
                        'my-input-class-mask': true,
                        'form-control': true,
                      }"
                      autocomplete="off"
                      :required="true"
                      disabled
                    />
                    <span class="error-span" v-if="nao_se_cadastra"
                      >- {{ notificacao_infos.mensagem }}</span
                    >
                  </b-col>

                  <b-col cols="12" lg="6">
                    <b-form-group label-for="email">
                      <input-with-label
                        id="email"
                        class="form-control email"
                        type="email"
                        label="E-mail*"
                        v-model="usuario.email"
                        :input-class="{
                          error: usuario.email
                            ? usuario.email.indexOf('@') === -1
                            : false,
                          'my-input-class': true,
                        }"
                        label-class-custom="my-label-class"
                        required
                        :disabled="loading || msgSucesso"
                      />
                    </b-form-group>
                    <span class="error-span" v-if="validaCaracterEmail">
                      E-mail inválido</span
                    >
                  </b-col>

                  <b-col cols="12" lg="6">
                    <b-form-group label-for="emailConfirm">
                      <input-with-label
                        label-for="emailConfirm"
                        label="Confirmar e-mail*"
                        class="form-control confirmarEmail"
                        type="email"
                        id="emailConfirm"
                        v-model="usuario.emailConfirm"
                        :input-class="{
                          error: validaConfirmaEmail,
                          'my-input-class': true,
                        }"
                        label-class-custom="my-label-class"
                        @keyup.native="
                          validaItem(
                            usuario.email,
                            usuario.emailConfirm,
                            'email'
                          )
                        "
                        onpaste="return false"
                        ondrop="return false"
                        autocomplete="emailConfirm-"
                        required
                        :disabled="loading || msgSucesso"
                      />
                    </b-form-group>
                    <span class="error-span" v-if="validaConfirmaEmail">
                      - Ambos os campos de email devem ser iguais
                    </span>
                  </b-col>

                 <b-col cols="12" lg="6">
                    <!-- aqui -->
                    <vue-tel-input
                      :inputOptions="inputTelOptions"
                      :preferredCountries="['BR', 'AR', 'US', 'ES']"
                      @input="telValidate"
                      v-model="usuario.phone"
                      id="celular"
                      label="Celular*"
                      type="tel"
                      name="celular"
                      class="form-control"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      autocomplete="off"
                      minlength="10"
                      required
                    ></vue-tel-input>
                    <!-- <input-with-label-mask
                      id="celular"
                      class="form-control celular"
                      type="tel"
                      label="Celular*"
                      v-model="usuario.phone"
                      :input-class="{
                        error: usuario.phone
                          ? !isTelefoneValido || errors.celular
                          : false,
                        'my-input-class-mask': true,
                        'form-control': true,
                      }"
                      :mask="['(##) ####-####', '(##) #####-####']"
                      minlength="10"
                      autocomplete="off"
                      :required="true"
                      :disabled="loading || msgSucesso"
                    ></input-with-label-mask> -->

                    <span
                      class="error-span"
                      v-if="
                        usuario.phone && (!isTelefoneValido || errors.celular)
                      "
                      >- Número de Celular inválido</span
                    >
                  </b-col>

                  <b-col cols="12" lg="6">
                    <input-with-label-mask
                      id="cep"
                      class="form-control cep"
                      label="CEP*"
                      v-model="usuario.cep"
                      placeholder="CEP"
                      :input-class="{
                        'my-input-class-mask': true,
                        'form-control': true,
                      }"
                      :mask="'#####-###'"
                      autocomplete="off"
                      :disabledMasked="true"
                      required
                      :disabled="loading || msgSucesso"
                    ></input-with-label-mask>
                  </b-col>

                  <b-col cols="12" lg="12" v-if="!msgSucesso">
                    <b-button
                      class="btn-enviar-cadastro"
                      type="submit"
                      :disabled="
                        !todosCamposPreenchidos() ||
                        !isTelefoneValido ||
                        validaConfirmaEmail ||
                        errors.email ||
                        errors.cpf ||
                        nao_se_cadastra ||
                        hasPlusSign ||
                        confirmEmailHasPlusSign ||
                        usuario.email.indexOf('@') === -1 ||
                        !usuario.dataNascimento
                      "
                      >Enviar</b-button
                    >
                    <span
                      class="feedback-btn"
                      v-if="
                        !todosCamposPreenchidos() ||
                        !isTelefoneValido ||
                        validaConfirmaEmail ||
                        errors.email ||
                        errors.cpf ||
                        nao_se_cadastra ||
                        hasPlusSign ||
                        confirmEmailHasPlusSign ||
                        usuario.email.indexOf('@') === -1 ||
                        !usuario.dataNascimento
                      "
                      >- Preencha todos os campos</span
                    >
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>

          <div v-if="loading_modal" class="loading-modal">
            <b-spinner
              style="
                width: 3rem;
                height: 3rem;
                border-color: #e6c156;
                border-right-color: transparent;
                --bs-spinner-animation-speed: 0.5s;
              "
              label="Large Spinner"
              variant="warning"
            ></b-spinner>
          </div>
        </b-modal>
      </b-container>
    </div>
  </div>
</template>

<script>
import InformacoesEtapas from "@/components/InformacoesEtapas.vue";
import InputWithLabelMask from "@/components/InputWithLabelMask.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import validateCPF from "@/validator/cpf.js";
import calculaIdade from "@/validator/idade.js";
import { api } from "@/services.js";
import { VueTelInput } from "vue-tel-input";
export default {
  name: "BannerStockClub",
  components: {
    InputWithLabelMask,
    InformacoesEtapas,
    InputWithLabel,
    VueTelInput
  },
  props: {
    fanpush: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loading_modal: false,
      msgSucesso: false,
      msgErro: false,
      texto_erro: "",
      showMessage: true,
      usuario: {
        name: "",
        genero: "",
        cpf: "",
        dataNascimento: "",
        email: "",
        emailConfirm: "",
        phone: "",
        cep: "",
        cadastroCompleto: false,
        sobrenome: "",
      },
      nao_se_cadastra: false,
      errors: {
        email: false,
        cpf: false,
        celular: false,
      },
      selectedOption: "default",
      options: [
        { value: "default", text: "Selecione o gênero" },
        { value: "Feminino", text: "Feminino" },
        { value: "Masculino", text: "Masculino" },
        { value: "não-binário", text: "Não-binário" },
        { value: "prefiro não dizer", text: "Prefiro não dizer" },
      ],
      inputTelOptions: {
        disabledFetchingCountry: false, 
        showDialCodeInInput: true,
        placeholder: "Digite seu número de telefone",
      },
    };
  },
  computed: {
    usuario_logado() {
      return this.$store.state.login || window.localStorage.token;
    },
    btn_ingresso() {
      return this.$store.state.btn_ingresso ||
        window.sessionStorage.btn_ingresso
        ? JSON.parse(window.sessionStorage.btn_ingresso)
        : {
            show: false,
            link: "",
          };
    },
    isTelefoneValido() {
      return this.usuario.phone.length >= 14;
    },
    hasPlusSign() {
      return this.usuario.email.includes("+");
    },
    confirmEmailHasPlusSign() {
      if (this.usuario && this.usuario.emailConfirm) {
        return this.usuario.emailConfirm.includes("+");
      }
      return false;
    },
    validaCaracterEmail() {
      if (this.usuario.email) {
        return this.hasPlusSign;
      } else {
        return false;
      }
    },
    validaConfirmaEmail() {
      if (this.usuario.emailConfirm) {
        return (
          this.usuario.emailConfirm.indexOf("@") === -1 ||
          this.usuario.email != this.usuario.emailConfirm ||
          this.errors.email
        );
      } else {
        return false;
      }
    },
  },
  methods: {
     telValidate(telnumber, objTel) {
      this.usuario.phone = objTel.number;
      if (objTel.valid) {
        this.errors.celular = false;
      } else {
        this.errors.celular = true;
      }
    },
    onSubmit() {
      this.loading_modal = true;
      this.usuario.cadastroCompleto = true;
      this.msgErro = false;
      this.msgSucesso = false;
      this.texto_erro = "";

      api
        .put("/Perfil", this.usuario)
        .then(() => {
          this.$store.dispatch("getUsuario");
          this.msgSucesso = true;
          setTimeout(() => {
            this.$bvModal.hide("modal-atualizar-cadastro");
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.msgErro = true;
          this.texto_erro = error.response
            ? error.response.data
            : "Ocorreu um erro ao processar a requisição";
        })
        .finally(() => {
          this.loading_modal = false;
        });
    },
    todosCamposPreenchidos() {
      return (
        this.usuario.emailConfirm &&
        this.usuario.name &&
        this.usuario.genero &&
        this.usuario.cpf &&
        this.usuario.dataNascimento &&
        this.usuario.email &&
        this.usuario.phone &&
        this.usuario.cep
      );
    },
    anoCadastro(date) {
      return new Date(date).getFullYear();
    },

    validaDocumento() {
      this.errors.cpf = true;
      if (this.usuario.cpf.length >= 11) {
        this.errors.cpf = !validateCPF(this.usuario.cpf);
      }
      if (!this.usuario.cpf.length) this.errors.cpf = false;
    },
    validaItem(item1, item2, field) {
      this.errors[field] = false;
      if (item1.trim() != item2.trim()) this.errors[field] = true;
    },
    validaIdade(e) {
      let value = e.target.value;
      this.nao_se_cadastra = false;
      if (value.length >= 10) {
        this.notificacao_infos = {
          variant: "primary",
          mensagem: "",
          count_timer: 0,
        };

        const idade = calculaIdade(value);

        if (idade < 14) {
          if (idade == false) {
            this.notificacao_infos = {
              variant: "danger",
              mensagem:
                "Data informada inválida, por favor coloque uma data válida",
              count_timer: 10,
            };
          } else {
            this.nao_se_cadastra = true;
            this.notificacao_infos = {
              variant: "danger",
              mensagem:
                "Usuário menor de 14 anos NÃO pode ser cadastrar na plataforma",
              count_timer: 10,
            };
          }
        }
      }
    },
  },

  filters: {
    dateFormat(date) {
      const data = new Date(date);
      const opcoes = { day: "2-digit", month: "2-digit", year: "numeric" };
      const dataFormatada = data.toLocaleDateString("pt-BR", opcoes);

      return dataFormatada;
    },
  },

  mounted() {
    this.loading = true;
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
      ? this.$store.state.user
      : null;

    setTimeout(() => {
      this.loading = false;
      const bg = document.querySelector(".banner-stock-club");
      if (
        bg &&
        this.usuario.pilotoFavorito &&
        this.usuario.pilotoFavorito.background
      )
        bg.style.backgroundImage = `url('${this.usuario.pilotoFavorito.background}')`;

      if (!this.usuario.cadastroCompleto) {
        this.$bvModal.show("modal-atualizar-cadastro");
      }
    }, 200);
  },
};
</script>

<style scoped>
.loading-modal {
  padding: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msg-sucesso .nome-modal,
.msg-erro .nome-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px !important;
  padding: 30px 0;
}
input:disabled {
  background-color: #333 !important;
  opacity: 0.7;
  cursor: auto !important;
  padding: 10px !important;
  color: #868686 !important;
}
.error-span {
  color: #ff1717;
  margin: 20px 0;
  margin-top: -25px;
  display: block;
  font-size: 14px;
}
.btn-enviar-cadastro,
button.btn.btn-enviar-cadastro.btn-secondary {
  padding: 10px !important;
  margin: 0 auto;
  display: block;
  background-color: #f9c14e;
  border: none;
  border-radius: 5px;
  width: 220px;
  height: 40px;
  color: #000000;
  font-size: 14px;
  font-family: "Uniwars-W00-SemiBold";
  font-style: italic;
  margin-bottom: 30px;
}
.btn-enviar-cadastro:disabled {
  background-color: gray !important;
  color: #000 !important;
}
.btn-enviar-cadastro:hover {
  background-color: #e6c156;
}

.dados-cadastro .form-control::placeholder {
  color: white;
}
.dados-cadastro .form-control,
.dados-cadastro select {
  border: 1px solid #e6c156;
  background-color: transparent;
  margin-bottom: 30px;
  color: #ffffff;
  height: 40px;
}

.dados-cadastro select {
  height: 40px;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-radius: 0.375rem;
  background-color: #000 !important;
}

.dados-cadastro option {
  background-color: #000 !important;
}

.dados-cadastro {
  width: 93%;
  margin: 0 auto;
  margin-top: 40px !important;
}
.linha {
  background-color: #f9c14e;
  height: 1px;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.logostock {
  margin: 0 auto;
  display: block;
}
.txt-modal {
  color: #ffffff;
  font-size: 19px;
  font-family: "MetronicPro-Regular";
  font-style: italic;
  text-align: center;
}
.nome-modal {
  margin-top: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-family: "MetronicPro-Bold";
  font-style: italic;
}
.info-etapa {
  color: #e6c156 !important;
}

.piloto-carteirinha {
  position: relative;
  top: 80px;
  left: -20px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.carteirinha {
  min-height: 30px;
}
.carteirinha h4 {
  font-family: "MetronicPro-Bold";
  color: #000;
  font-size: 15px;
  position: relative;
  top: 70px;
  width: 87%;
  text-align: center;
}

.carteirinha p {
  font-family: "MetronicPro-Bold";
  color: #000;
  font-size: 12px;
  position: relative;
  top: 62px;
  width: 87%;
  text-align: center;
}

.btn-banner,
.info-etapa {
  display: block;
  align-self: flex-end;
  position: relative;
  top: -100px;
}

.btn {
  font-family: "Uniwars-W00-Light";
  font-size: 15px;
  padding: 15px 60px;
  background-color: transparent;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid;
  text-align: center;
  color: var(--primary-color);
  transition: 0.3s;
  display: flex;
  justify-content: center;
}

.btn strong {
  margin-right: 10px;
  font-family: "Uniwars-W00-Heavy-Italick";
}

.btn:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.btn-desativado {
  margin-top: 20px;
  font-family: "Uniwars-W00-Light";
  font-size: 15px;
  padding: 15px 10px;
  background-color: transparent;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid;
  text-align: center;
  color: #7c7c7c;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  cursor: default;
  pointer-events: none;
}

.btn-desativado {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.info-etapa {
  text-align: right;
}

@media (max-width: 1399px) {
  .carteirinha h4,
  .carteirinha p {
    width: 100%;
  }
}
.info-piloto {
  z-index: 999;
  position: relative;
  max-width: 30%;
  top: -120px;
  left: -80px;
}
.info-piloto.default {
  top: 120px;
}
.info-piloto.default button {
  width: 430px;
  font-weight: 600;
}
.info-piloto span {
  display: block;
}
.info-piloto span:first-child {
  color: var(--primary-color);
  font-weight: 600;
  font-family: "Uniwars-W00-Heavy-Italick";
  font-size: 70px;
  text-shadow: #00000079 2px 4px;
}
.info-piloto span:last-child {
  color: #fff;
  font-size: 24px;
  margin-top: -20px;
  font-weight: 600;
}
@media (max-width: 989px) {
  .error-span {
    margin-top: -10px;
  }

  .dados-cadastro {
    margin-top: 15px !important;
  }
  .nome-modal {
    font-size: 16px;
    margin-top: 10px !important;
  }

  .btn-enviar-cadastro {
    width: 100%;
    margin-bottom: 100px;
  }
  #genero {
    margin-bottom: 15px !important;
  }
  .info-piloto {
    max-width: 100%;
    top: 20px;
    left: 0;
  }
  .info-etapa {
    text-align: center;
    margin-top: 300px;
  }

  .btn-banner {
    margin-top: 20px;
  }

  .banner-stock-club {
    height: auto;
  }

  .btn {
    padding: 15px 0px;
  }

  .carteirinha {
    background-position: center;
    margin-top: 50px;
    /* background-size: contain; */
  }

  .col-carteirinha {
    display: grid;
    justify-content: center;
  }

  .banner-stock-club {
    background-image: none !important;
  }

  .carteirinha h4 {
    top: 65px;
    width: 100%;
  }
  .carteirinha.tem-piloto h4 {
    top: 100px;
  }

  .carteirinha p {
    width: 100%;
    top: 55px;
  }
  .carteirinha.tem-piloto p {
    top: 95px;
  }
  .piloto-carteirinha {
    top: 100px;
  }

  .piloto-carteirinha {
    left: 0px;
    width: 250px;
  }
}

@media (min-width: 1500px) {
  .carteirinha {
    margin-top: 150px;
    min-height: 500px;
  }
}

@media (min-width: 990px) and (max-width: 1499px) {
  .info-piloto {
    left: -45px !important;
  }
}

.feedback-btn {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  display: flex;
  justify-content: center;
}
.form-control.vue-tel-input {
  display: flex;
  height: auto;
  padding: 5px 0px;
}
.form-control.vue-tel-input input {
  background: transparent !important;
  color: #fff;
  font-size: 16px;
}
.form-control.vue-tel-input input:focus,
.form-control.vue-tel-input:focus-within {
  border-color: #fff !important;
  outline: none;
  box-shadow: none;
}
.form-control.vue-tel-input input::placeholder {
  color: var(--primary-color);
  font-size: 16px;
}

.form-control.vue-tel-input:focus-within input::placeholder {
  color: rgba(147, 147, 147, 0.5);
}
.form-control.vue-tel-input .vti__dropdown:hover,
.form-control.vue-tel-input .vti__dropdown.open {
  background: rgba(147, 147, 147, 0.3);
}
.form-control.vue-tel-input .vti__dropdown-list {
  background: #000;
  color: #fff;
  z-index: 9999 !important;
}
.form-control.vue-tel-input .vti__dropdown-item.highlighted {
  background: #5a5a5a;
}
</style>
