<template>
  <div>

    <b-container v-if="$route.params.categoria == 'turismo-nacional'">
      <b-row>
        <b-col class="titulo-classificacao titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="abaAtiva == 0" class="titulo"><strong>CLASSIFICAÇÃO</strong> Overall A </h1>
          <h1 v-if="abaAtiva == 1" class="titulo"><strong>CLASSIFICAÇÃO</strong> Sprint A</h1>
          <h1 v-if="abaAtiva == 2" class="titulo"><strong>CLASSIFICAÇÃO</strong> Endurance</h1>
          <h1 v-if="abaAtiva == 3" class="titulo"><strong>CLASSIFICAÇÃO</strong> Overall B </h1>
          <h1 v-if="abaAtiva == 4" class="titulo"><strong>CLASSIFICAÇÃO</strong> Sprint B</h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>
    </b-container>

    <!-- <b-container v-if="$route.params.categoria == 'e-stock'">
      <b-row>
        <b-col class="titulo-classificacao titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="abaAtiva == 0" class="titulo"><strong>CLASSIFICAÇÃO</strong> PRO </h1>
          <h1 v-if="abaAtiva == 1" class="titulo"><strong>CLASSIFICAÇÃO</strong> AM</h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>
    </b-container> -->


    <div class="item-select" v-if="$route.params.categoria == 'proseries'">
      <label for="">Escolha a temporada</label>
      <b-form-select class="select form-control" v-model="ano_selecionado" :options="anos_options" @change="changeAno"
        required></b-form-select>
    </div>

    <div class="item-select" v-else-if="$route.params.categoria != 'e-stock'">
      <label for="">Escolha a temporada</label>
      <b-form-select class="select form-control" v-model="ano_selecionado" :options="anos_options_turismo"
        @change="changeAno" required></b-form-select>
    </div>

    <!------ CLASSIFICACAO TODAS AS CATEGORIAS MENOS TURISMO NACIONAL E E-STOCK-------->
    <b-tabs content-class="mt-3" v-if="$route.params.categoria != 'turismo-nacional' && $route.params.categoria != 'e-stock'">
      <!------- TAB PILOTOS -------->

      <b-tab v-if="$route.params.categoria == 'formula-4'" :title="$t('title.pilotosclassificacao')" active
        @click.prevent="fetchClassificaoPiloto">
        <div v-if="!loading">
          <div class="bg-linha" v-for="(item, index) in classificacaoPilotos" :key="item.idPiloto">
            <div class="classificacao-pilotos no-scroll" v-if="index < limit" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao">
                <img class="img-piloto piloto-classificacao-item" :src="item.imagem"
                  @mouseenter="fotoPiloto(item, 'mouseenter', $event)"
                  @mouseleave="fotoPiloto(item, 'mouseleave', $event)" />
              </div>
              <div class="info-classificacao">
                <p class="nome-classificacao">
                  {{ primeiroNome(item.nome) }}
                </p>
                <p class="nome-classificacao sobrenome">
                  <strong>{{ ultimoNome(item.nome) }}</strong>
                </p>
              </div>
              <div class="info-classificacao">
                <img class="classificacao-montadora" :src="item.logoMontadora" />
              </div>
              <div class="info-classificacao">
                <img class="carro" :src="item.carro" />
              </div>
              <div class="info-classificacao">
                <img class="classificacao-equipe" :src="item.logoEquipe" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab v-else-if="$route.params.categoria != 'e-stock'" title="PILOTOS" active @click.prevent="fetchClassificaoPiloto">
        <div v-if="!loading">
          <div class="bg-linha" v-for="(item, index) in classificacaoPilotos" :key="item.idPiloto">
            <div class="classificacao-pilotos no-scroll" v-if="index < limit" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao">
                <img class="img-piloto piloto-classificacao-item" :src="item.imagem"
                  @mouseenter="fotoPiloto(item, 'mouseenter', $event)"
                  @mouseleave="fotoPiloto(item, 'mouseleave', $event)" />
              </div>
              <div class="info-classificacao">
                <p class="nome-classificacao">
                  {{ primeiroNome(item.nome) }}
                </p>
                <p class="nome-classificacao sobrenome">
                  <strong>{{ ultimoNome(item.nome) }}</strong>
                </p>
              </div>
              <div class="info-classificacao">
                <img class="classificacao-montadora" :src="item.logoMontadora" />
              </div>
              <div class="info-classificacao">
                <img class="carro" :src="item.carro" />
              </div>
              <div class="info-classificacao">
                <img class="classificacao-equipe" :src="item.logoEquipe" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <!------- TAB EQUIPES -------->
      <!--<b-tab v-if="$route.params.categoria == 'formula-4'" :title="$t('title.equipesclassificacao')"
        @click.prevent="fetchClassificaoEquipe">
        <div v-if="!loading">
          <div class="bg-linha" v-for="(item, index) in classificacaoEquipes" :key="item.idEquipe">
            <div class="classificacao-pilotos tabela-equipes" data-anima="bottom"
              v-if="index < limit && item.pilotosEquipe.length">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacaoEquipe }}</p>
                <p class="pts">pts</p>
              </div>
              <div>
                <img class="logo-equipe" :src="item.logoEquipeB" alt="Logo da equipe" />
              </div>
              <div v-for="p in item.pilotosEquipe" :key="p.idPiloto">
                <img class="img-piloto classificacao-equipe" :src="p.imagem" alt="Imagem do piloto" />
              </div>

              <div v-for="p in item.pilotosEquipe" :key="p.idPiloto">
                <img class="carro tresd classificacao-equipe" :src="p.carro" alt="Imagem do carro" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>-->
      <b-tab 
      v-if="$route.params.categoria != 'e-stock' && $route.params.categoria != 'formula-4'"
      title="EQUIPES" @click.prevent="fetchClassificaoEquipe">
        <div v-if="!loading">
          <div class="bg-linha" v-for="(item, index) in classificacaoEquipes" :key="item.idEquipe">
            <div class="classificacao-pilotos tabela-equipes" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacaoEquipe }}</p>
                <p class="pts">pts</p>
              </div>
              <div>
                <img class="logo-equipe" :src="item.logoEquipeB" alt="Logo da equipe" />
              </div>
              <div v-for="p in item.pilotosEquipe" :key="p.idPiloto">
                <img class="img-piloto classificacao-equipe" :src="p.imagem" alt="Imagem do piloto" />
              </div>

              <div v-for="p in item.pilotosEquipe" :key="p.idPiloto">
                <img class="carro tresd classificacao-equipe" :src="p.carro" alt="Imagem do carro" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <div v-if="loading" data-anima="bottom">
        <div class="d-flex" style="height: 100px">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
        <div class="d-flex" style="height: 100px">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
        <div class="d-flex" style="height: 100px">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
        <div class="d-flex" style="height: 100px">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
        <div class="d-flex" style="height: 100px">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
      </div>

      <div class="link-router" v-if="$route.name != 'Classificação Geral'">
        <router-link v-if="$route.params.categoria == 'formula-4'"
          :to="`/stockcar/${$route.params.categoria}/classificacao`" class="btn-stock">{{
            $t("button.classificacaoCompleta") }}</router-link>
        <router-link v-else :to="`/stockcar/${$route.params.categoria}/classificacao`" class="btn-stock">Classificação
          completa</router-link>
      </div>
    </b-tabs>

    <!------ CLASSIFICACAO E-STOCK -------->
    <!-- <b-tabs content-class="mt-3" v-else-if="$route.params.categoria == 'e-stock'" class="tab-e-stock" v-model="abaAtiva"> -->
      <!------- TAB CATEGORIA 1 -------->
      <!-- <b-tab title="PRO" :value="0" active @click="fetchEStock(0)">
        <div v-if="!loading && estock.length > 0">
          <div class="bg-linha" v-for="(item, index) in estock" :key="index">
            <div v-if="index < limit" class="classificacao-e-stock-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="item.imagem"
                    @mouseenter="fotoPiloto(item, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(item, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ item.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(item.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(item.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="estock.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>
      </b-tab> -->

      <!------- TAB CATEGORIA 2 -------->

      <!-- <b-tab :value="1" title="AM" @click="fetchEStock(1)">
        <div v-if="!loading && estock.length > 0">

          <div class="bg-linha" v-for="(item, index) in estock" :key="index">
            <div v-if="index < limit" class="classificacao-e-stock-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="item.imagem"
                    @mouseenter="fotoPiloto(item, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(item, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ item.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(item.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(item.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="estock.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>

      </b-tab>

    </b-tabs> -->



    <!------ CLASSIFICACAO TURISMO -------->
    <b-tabs content-class="mt-3" v-else-if=" this.$route.params.categoria != 'e-stock'" class="tab-turismo-nacional" v-model="abaAtiva">
      <!------- TAB CATEGORIA 1 -------->

      <b-tab title="Overall A" :value="0" active @click="fetchTurismo(0)">
        <div v-if="!loading && turismo.length > 0">
          <div class="bg-linha" v-for="(item, index) in turismo" :key="index">
            <div v-if="index < limit" class="classificacao-turismo-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto" v-for="(piloto, pilotoIndex) in item.pilotos"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="piloto.imagem"
                    @mouseenter="fotoPiloto(piloto, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(piloto, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ piloto.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(piloto.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(piloto.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="turismo.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>
      </b-tab>

      <!------- TAB CATEGORIA 2 -------->

      <b-tab :value="1" title="Sprint A" @click="fetchTurismo(1)">
        <div v-if="!loading && turismo.length > 0">

          <div class="bg-linha" v-for="(item, index) in turismo" :key="index">
            <div v-if="index < limit" class="classificacao-turismo-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto" v-for="(piloto, pilotoIndex) in item.pilotos"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="piloto.imagem"
                    @mouseenter="fotoPiloto(piloto, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(piloto, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ piloto.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(piloto.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(piloto.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="turismo.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>

      </b-tab>

      <!------- TAB CATEGORIA 3 -------->

      <b-tab :value="2" title="Endurance" @click="fetchTurismo(2)">
        <div v-if="!loading && turismo.length > 0">
          <div class="bg-linha" v-for="(item, index) in turismo" :key="index">
            <div v-if="index < limit" class="classificacao-turismo-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto" v-for="(piloto, pilotoIndex) in item.pilotos"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="piloto.imagem"
                    @mouseenter="fotoPiloto(piloto, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(piloto, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ piloto.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(piloto.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(piloto.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="turismo.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>
      </b-tab>

      <!------- TAB CATEGORIA 4 -------->

      <b-tab :value="3" title="Overall B" @click="fetchTurismo(3)">
        <div v-if="!loading && turismo.length > 0">
          <div class="bg-linha" v-for="(item, index) in turismo" :key="index">
            <div v-if="index < limit" class="classificacao-turismo-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto" v-for="(piloto, pilotoIndex) in item.pilotos"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="piloto.imagem"
                    @mouseenter="fotoPiloto(piloto, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(piloto, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ piloto.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(piloto.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(piloto.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="turismo.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>
      </b-tab>

      <!------- TAB CATEGORIA 5 -------->

      <b-tab :value="4" title="Sprint B" @click="fetchTurismo(4)">
        <div v-if="!loading && turismo.length > 0">
          <div class="bg-linha" v-for="(item, index) in turismo" :key="index">
            <div v-if="index < limit" class="classificacao-turismo-pilotos no-scroll" data-anima="bottom">
              <div class="posicao">
                <p>{{ index + 1 }}</p>
              </div>
              <div class="div-pontos">
                <p class="pontos">{{ item.pontuacao }}</p>
                <p class="pts">pts</p>
              </div>
              <div class="info-classificacao-turismo d-flex infoPiloto" v-for="(piloto, pilotoIndex) in item.pilotos"
                :key="pilotoIndex">
                <template>
                  <img class="img-piloto piloto-classificacao-item" :src="piloto.imagem"
                    @mouseenter="fotoPiloto(piloto, 'mouseenter', $event)"
                    @mouseleave="fotoPiloto(piloto, 'mouseleave', $event)" />
                  <div>
                    <p class="categoria-turismo">{{ piloto.subcategoria }}</p>
                  </div>
                  <div>
                    <p class="nome-classificacao">
                      {{ primeiroNome(piloto.nome) }}
                    </p>
                    <p class="nome-classificacao sobrenome">
                      <strong>{{ ultimoNome(piloto.nome) }}</strong>
                    </p>
                  </div>
                </template>
              </div>
            </div>

          </div>
        </div>

        <div v-if="loading" data-anima="bottom">
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
          <div class="d-flex" style="height: 100px">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="60%"></b-skeleton>
          </div>
        </div>

        <div v-if="turismo.length == 0">
          <p class="msgResultado">NENHUM RESULTADO FOI ENCONTRADO!</p>
        </div>
      </b-tab>




      <b-tab :title="titulo" @click="irSite()">

      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import { EventBus } from "@/main.js";

export default {
  name: "ClassificacaoGeral",
  props: {
    limit: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      titulo: "Mais Classificações",
      abaAtiva: 0,
      classificacaoPilotos: [],
      classificacaoEquipes: [],
      id_categoria: 1,
      loading: false,
      turismo: [],
      estock: [],
      pilotos: [],
      subcategoria: "",
      tipo: "",
      anos_options: [
        {
          value: "2023",
          text: "2023",
        },
        {
          value: "2024",
          text: "2024",
        },
      ],
      anos_options_turismo: [
        {
          value: "2024",
          text: "2024",
        },
      ],
      ano_selecionado: new Date().getFullYear().toString(),
    };
  },
  methods: {

    irSite() {
      window.open("https://www.chronon.com.br/turismo-nacional-2024/", "_blank");
    },

    changeAno() {
      this.classificacaoPilotos = [];
      this.classificacaoEquipes = [];
      this.turismo = [];
      this.estock = [];

      if (this.$route.params.categoria != 'turismo-nacional' && this.$route.params.categoria != 'e-stock') {
        this.fetchClassificaoPiloto();
        this.fetchClassificaoEquipe();
      } else if(this.$route.params.categoria == 'turismo-nacional') {
        this.fetchTurismo();
      } else {
        this.fetchEStock()        
      }
    },
    primeiroNome(nome) {
      return nome.replace(/ .*/, "");
    },
    ultimoNome(nome) {
      let splitNome = nome.split(" ");
      let sobrenome = splitNome.slice(1).join(" ");
      return sobrenome;
    },
    fotoPiloto(piloto, event, element) {
      if (event == "mouseenter") {
        element.target.src = piloto.imagemB;
      } else {
        element.target.src = piloto.imagem;
      }
    },
    fetchClassificaoPiloto() {
      this.loading = true;

      api
        .get(
          `Classificacao/categoria/${this.id_categoria}/pilotos?ano=${this.ano_selecionado}`
        )
        .then((resp) => {
          this.classificacaoPilotos = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    fetchClassificaoEquipe() {
      this.loading = true;
      api
        .get(
          `Classificacao/categoria/${this.id_categoria}/equipes?ano=${this.ano_selecionado}`
        )
        .then((resp) => {
          this.classificacaoEquipes = resp.data;

        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    fetchEStock(value) {
      this.loading = true;

      if (value == 0) {
        this.subcategoria = 'Amador';
      } else if (value == 1) {
        this.subcategoria = 'PRO';
      } 

      api
        .get(`Classificacao/categoria/${this.id_categoria}/pilotos?ano=${this.ano_selecionado}&subcategoria=${this.subcategoria}
        `)
        .then((resp) => {
          this.estock = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },

    fetchTurismo(value) {
      this.loading = true;

      if (value == 0) {
        this.subcategoria = "A";
        this.tipo = "";
      } else if (value == 1) {
        this.subcategoria = "A";
        this.tipo = "sprint";
      } else if (value == 2) {
        this.subcategoria = "";
        this.tipo = "endurance";
      } else if (value == 3) {
        this.subcategoria = "B";
        this.tipo = "";
      } else if (value == 4) {
        this.subcategoria = "B";
        this.tipo = "sprint";
      }

      api
        .get(`Turismo?idCategoria=6&year=${this.ano_selecionado}&subcategoria=${this.subcategoria}&tipo=${this.tipo}`
        )
        .then((resp) => {
          this.turismo = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.id_categoria =
      mapCategorias[this.$route.params.categoria || "proseries"];


    if (this.$route.params.categoria != 'turismo-nacional' && this.$route.params.categoria != 'e-stock') {
      this.fetchClassificaoPiloto();
    } else if (this.$route.params.categoria == 'turismo-nacional') {
      this.fetchTurismo(0);
    }else {
      this.fetchEStock(0);
    }


  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.id_categoria = mapCategorias[data || "proseries"];

      if (this.$route.params.categoria != 'turismo-nacional' && this.$route.params.categoria != 'e-stock') {
        this.fetchClassificaoPiloto();
        this.fetchClassificaoEquipe();
      } else if(this.$route.params.categoria == 'turismo-nacional'){
        this.fetchTurismo(0);
      } else{
        this.fetchEStock(0)
      }


    });
  },
};
</script>

<style scoped>
.posicao p {
  font-family: "Uniwars-W00-Black";
  text-align: center;
  color: #707070;
  font-size: 22px;
  margin-bottom: 0px !important;
  width: 40px;
}

.div-pontos {
  text-align: left;
  width: 170px;
}

.info-classificacao {
  width: 110px;
  text-transform: capitalize;
}

.logo-equipe {
  width: 80px;
  text-align: center;
}

.tresd {
  margin-top: 20px;
}

.link-router {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.btn-stock {
  margin: 0 auto;
}

.piloto-classificacao-item:hover {
  width: 95px;
  transform: scale(2);
  transition: all 0.3s;
  position: relative;
  z-index: 99999999999;
}

.classificacao-equipe {
  width: 80px;
}

.img-piloto {
  max-width: 100px;
}

.classificacao-pilotos.no-scroll,
.classificacao-turismo-pilotos.no-scroll,
.classificacao-e-stock-pilotos.no-scroll {
  overflow: initial;
}

.categoria-turismo {
  font-family: "UniwarsW00Heavy";
  color: #707070;
  font-size: 22px;
  margin-bottom: 0px;
}

.infoPiloto {
  gap: 15px;
}

.info-classificacao-turismo {
  width: 230px;
  align-items: center;
}

@media (max-width: 989px) {
  .nav-tabs {
    flex-direction: column !important;
    align-content: center !important;
    width: 300px !important;
  }

  .classificacao-pilotos.no-scroll,
  .classificacao-turismo-pilotos.no-scroll,
  .classificacao-e-stock-pilotos.no-scroll {
    overflow: auto;
  }

  .classificacao-turismo-pilotos,
  .classificacao-e-stock-pilotos {
    margin-left: 0px !important;
  }

  .classificacao-turismo-pilotos.no-scroll,
  .classificacao-e-stock-pilotos.no-scroll {
    padding: 10px 20px 0px 0px;
  }


  .container-classificacao .nav-tabs {
    width: 300px !important;
  }

  .tab-turismo-nacional ul {
    width: 100% !important;
  }

  .tab-e-stock ul {
    width: 100% !important;
  }
}

.classificacao-turismo-pilotos,
.classificacao-e-stock-pilotos {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
}

.item-select {
  margin-top: 25px !important;
}

.msgResultado {
  font-family: "MetronicPro-Bold";
  font-size: 24px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
