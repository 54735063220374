<template>
  <div class="container-etapa">
     <div class="d-flex box-geral ver-desktop container">
        <div class="faixa-etapa">
            <div class="d-flex proximaEtapa">
                <div class="d-flex dataProximaEtapa">
                    <div class="etapa-box">
                        {{numeroEtapa}}
                    </div>
                    
                    <div class="cronograma"><p>CRONOGRAMA</p></div>
                </div>
                
                <div>
                  <router-link class="vermais" v-if="etapa" :to="`/stockcar/${$route.params.categoria}/circuito/${etapa.idCircuito}`">Ver mais >>></router-link>
                </div>
            </div>

            <div class="d-flex circuito-box"  v-if="etapa">
                <div class="nome-circuito d-flex">
                    <div>
                        <h3 v-if="$route.params.categoria != 'formula-4'">{{etapa.circuito}}</h3>
                         <h3 v-else class="title-f4">{{etapa.circuito}}</h3>
                        <div class="dataEtapa" v-if="etapa"><strong>{{etapa.dataDia}}</strong> DE {{etapa.dataMes}} DE <strong>{{etapa.dataAno}}</strong></div>
                    </div>
                    <p v-if="$route.params.categoria != 'formula-4'">{{etapa.cidade}}</p>
                    <p v-else class="text-f4">{{etapa.cidade}}</p>
                </div>  
            </div>

            <div class="d-flex box-programacao" v-if="etapa && etapa.programacoes">
                    <div class="d-flex lista" v-for="(item, index) in programacao" :key="index">
                        <div class="diadasemana" v-if="$route.params.categoria != 'formula-4'"><p>{{item.diaProgramacao}}</p></div>
                        <div class="diadasemanaf4" v-else><p>{{item.diaProgramacao}}</p></div>
                        <div>
                            <div class="infoprogramacao" ref="htmlContainer" v-html="item.htmlProgramacao"></div>
                        </div>
                    </div>

                </div>
        </div>

        <img v-if="etapa"
                  :src="etapa.imagemCircuito"
                  alt="Imagem do circuito"
                  class="imgCircuito"
                />

     </div>

     <div class="d-flex box-geral-mobile ver-mobile container">
        <div class="faixa-etapa">
            <div class="d-flex proximaEtapa">
                <div class="d-flex dataProximaEtapa">
                    <div class="etapa-box">
                        {{numeroEtapa}}
                    </div>
                
                </div>
                
                <div class="boxvermais">
                   <router-link class="vermais" v-if="etapa" :to="`/stockcar/${$route.params.categoria}/circuito/${etapa.idCircuito}`">Ver mais >>></router-link>
                </div>
            </div>

            <div class="d-flex circuito-box"  v-if="etapa">
                <div class="nome-circuito d-flex">
                    <div>
                        <div class="cronograma"><p>CRONOGRAMA</p></div>
                        <h3 v-if="$route.params.categoria != 'formula-4'">{{etapa.circuito}}</h3>
                        <h3 v-else class="title-f4">{{etapa.circuito}}</h3>
                        <p v-if="$route.params.categoria != 'formula-4'">{{etapa.cidade}}</p>
                        <p v-else class="text-f4">{{etapa.cidade}}</p>
                    </div> 
                    <div class="dataEtapa" v-if="etapa"><strong>{{etapa.dataDia}}</strong> DE {{etapa.dataMes}} DE <strong>{{etapa.dataAno}}</strong></div>
                </div>  
            </div>

            <div class="d-flex box-programacao" v-if="etapa && etapa.programacoes">
                    <div class="d-flex lista" v-for="(item, index) in programacao" :key="index">
                       <div class="diadasemana" v-if="$route.params.categoria != 'formula-4'"><p>{{item.diaProgramacao}}</p></div>
                       <div class="diadasemanaf4" v-else><p>{{item.diaProgramacao}}</p></div>
                        <div>
                            <div class="infoprogramacao" ref="htmlContainer" v-html="item.htmlProgramacao"></div>
                        </div>
                    </div>
                    
                </div>
        </div>

        <img v-if="etapa"
                  :src="etapa.imagemCircuito"
                  alt="Imagem do circuito"
                  class="imgCircuito"
                />

     </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";
export default {
  name: "Programacao",
  data() {
    return {
      loading: false,
      etapa: null,
      idCircuito: null,
      programacao: []
    };
  },
  computed: {
     numeroEtapa() {
      if (!this.etapa) return '';
      const numero = this.etapa.etapa.match(/\d+/);
      return numero ? "Etapa " + numero[0] : this.etapa.etapa;
    }
 },
  methods: {
    fetchProximaEtapa(id_categoria) {
        this.loading = true;
        api
          .get(`Etapa/proxima/categoria/${id_categoria}`)
          .then((resp) => {
            this.etapa = resp.data;
            this.programacao = resp.data.programacao;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false;
          });
      
    }
  },
  mounted() {
    this.fetchProximaEtapa(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },

};
</script>

<style scoped>

.diadasemana {
    text-align: center;
 }

.dataEtapa {
    font-style: italic;
    color: var(--text-color);
    font-size: 18px;
    font-family: "MetronicPro-Light";
}

.etapa-box {
    text-transform: uppercase;
    width: auto;
    color: #000;
    font-family: "MetronicPro-Bold";
    background-color: #fff;
    font-size: 20px;
    padding: 5px 20px;
    position: relative;
    display: inline-block;
}
/*
.etapa-box:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 110px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent #fff #fff;
}
*/
.proximaEtapa {
    align-items: baseline;
    border-bottom: 1px solid #fff;
    justify-content: space-between;
}

.imgCircuito {
    width: 200px;
}

.nome-circuito {
    justify-content: space-between;
    gap: 20px;
    align-items: baseline;
    width: 100%;
}

.text-f4 {
    font-family: "Venera-300" !important;  
}

.title-f4 {
    font-family: "Venera-900" !important;  
}

.nome-circuito h3 {
    letter-spacing: 0px;
    margin-bottom: 0px;
    font-family: "Uniwars-W00-Black";
    color: var(--text-color);
    font-size: 36px;
    margin-top: 20px;
}

.nome-circuito p {
    margin-bottom: 0px;
    font-family: "UniwarsW00Regular";
    color: var(--text-color);
    font-size: 20px;
}

.circuito-box {
    align-items: center;
    gap: 60px;
}

.box-geral {
    align-items: center;
    justify-content: space-between;
}

.faixa-etapa {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.dataProximaEtapa {
    align-items: baseline;
    gap: 30px;
}

.cronograma p {
    letter-spacing: 0px;
    font-family: "MetronicPro-Bold";
    color: var(--text-color);
    margin-bottom: 0px;
    font-style: italic;
}

.vermais {
    font-family: "MetronicPro-Bold";
    color: var(--text-color);
    text-transform: uppercase;
    text-decoration: none;
}

.container-etapa {
    margin-top: 50px;
    min-height: 280px;
    background-image: var(--bg-programacao);
    background-size: cover;
    background-repeat: no-repeat;
}   

.box-programacao {
    margin-top: 20px;
    justify-content: start;
    gap: 30px;
}

.lista {
     gap: 20px
}

.diadasemana p {
    font-family: "Uniwars-W00-Light";
    font-size: 14px;
    background-color: var(--text-color);
    color: var(--title-color);
    padding: 5px 15px;
}

.diadasemanaf4 p {
    font-family: "Venera-700";
    font-size: 14px;
    background-color: var(--text-color);
    color: var(--title-color);
    padding: 5px 15px;
}

.infoprogramacao {
    font-family: "MetronicPro-Bold";
    color: var(--text-color);
    font-style: italic;
    font-size: 18px;
    margin-bottom: 0px;
}

@media (max-width: 990px) {
    .boxvermais {
        width: 180px;
    }

    .ver-desktop {
        display: none !important;
    }

    .faixa-etapa {
        width: 100%;
    }

    .etapa-box {
        font-size: 13px;
        width: auto;
    }

    .etapa-box:after {
        left: 90px;
        border-width: 15px;
    }
    .dataProximaEtapa {
        gap: 40px;
        width: 100%;
        justify-content: space-between;
    }

    .nome-circuito h3 {
        font-size: 24px;
    }

    .nome-circuito p, .dataEtapa {
        font-size: 15px;
    }

    .circuito-box {
        gap: 15px;
    }

    .nome-circuito {
        margin-top: 15px;
    }

    .faixa-etapa {
        width: 100%;
        padding-top: 30px;
    }

    .circuitomobile {
         width: 120px;
    }

    .container-etapa {
        margin-top: 30px;
    }

    .circuito-box {
        margin-bottom: 35px;
    }

    .lista {
        margin-bottom: 20px;
    }

    .vermais {
        position: relative;
        float: right;
    }

    .diadasemana {
         min-width: 120px;
        text-align: center;
    }

    .box-geral-mobile, .box-programacao {
        display: block !important;
    }
    
    .imgCircuito {
        margin: 0 auto;
        display: table;
    }
}

@media (min-width: 991px) {
    .ver-mobile {
        display: none !important;
    }

    .box-programacao {
        flex-wrap: wrap;
    }
}
</style>