<template>
  <section class="quiz">
    <div>
        <b-container v-if="!pesquisaFinalizada">
            <b-row>
                <b-col cols="12" lg="5" class="text-center col-foto">
                    <img :src="currentImage" @mouseover="changeImage" @mouseout="resetImage"  alt="Imagem numero 1" class="img-quiz" />
                </b-col>

                <b-col cols="12" lg="7">
                    <img src="@/assets/img/quiz/logo.png" alt="Logo stock quiz" class="img-logoquiz" />
                    <h3 class="pergunta-quiz">Com que idade de Felipe Fraga se tornou o campeão mais jovem da história da Stock Car?</h3>

                    <div class="box-respostas-quiz">
                        <b-button type="button" class="resposta-quiz">18 anos</b-button>

                        <b-button type="button" class="resposta-quiz">19 anos</b-button>

                        <b-button type="button" class="resposta-quiz">20 anos</b-button>

                        <b-button type="button" class="resposta-quiz">21 anos</b-button>
                    </div>
                </b-col>
                
                <b-col cols="12" lg="12" class="barra-progresso">
                    <div class="box-barra">
                        <b-progress value="25" max="100"></b-progress>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-else>
            <b-row class="row-resultado">
                <b-col cols="12" lg="5" class="text-center">
                    <img :src="currentImage2" @mouseover="changeImage2" @mouseout="resetImage2"  alt="Imagem numero 1" class="img-quiz" />
                </b-col>

                <b-col cols="12" lg="7">
                    <h3 class="parabens">PARABÉNS!</h3>
                    <p class="txt-resultado">VOCÊ ACERTOU XX PERGUNTAS</p>
                    <p class="compartilhar">COMPARTILHAR NA REDE SOCIAL</p>

                    <div class="d-flex redes-sociais">
                      <a href="#"><img src="@/assets/img/quiz/baixar.png" alt="Icone de baixar" /></a>
                      <a href="#"><img src="@/assets/img/quiz/comp.png" alt="botao de compartilhar" /></a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
export default {
  name: "StockQuiz",
  data() {
    return {
      pesquisaFinalizada: false,
      currentImage: require('@/assets/img/quiz/01.png'),
      originalImage: require('@/assets/img/quiz/01.png'),
      hoverImage: require('@/assets/img/quiz/01-sticker.png'), 
      currentImage2: require('@/assets/img/quiz/a-frente.png'),
      originalImage2: require('@/assets/img/quiz/a-frente.png'),
      hoverImage2: require('@/assets/img/quiz/a-tras.png'), 
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
   methods: {
    changeImage() {
      this.currentImage = this.hoverImage;
    },
    resetImage() {
      this.currentImage = this.originalImage;
    },

    changeImage2() {
      this.currentImage2 = this.hoverImage2;
    },
    resetImage2() {
      this.currentImage2 = this.originalImage2;
    },
  },
  components: {
    LogosHome,
  },
};
</script>

<style scoped>

.quiz {
    background-image: url('@/assets/img/quiz/bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.img-quiz {
    margin-top: 50px;
}

.img-logoquiz {
    margin-top: 50px;
    width: 190px;
}

.pergunta-quiz {
    color: #EABB4E;
    font-size: 20px;
    letter-spacing: 2.4px;
    font-family: "MetronicPro-Light";
    width: 550px;
    margin-top: 25px;
    margin-bottom: 40px;
}

.resposta-quiz {
    background-color: transparent;
    border-radius: 7px;
    border: 2px solid #F7B04D;
    color: white;
    font-size: 23px;
    letter-spacing: 2.3px;
    width: 350px;
    margin-bottom: 10px;
}

.resposta-quiz:hover {
    background-color: #F7B04D;
}

.box-respostas-quiz {
    display: grid;
}

.barra-progresso .progress-bar {
    background-color: #EABB4E !important;
    border-radius: 40px !important;
}

.barra-progresso  .progress {
    background-color: #707070 !important;
    border-radius: 40px !important;
    height: 20px !important;
}

.box-barra {
    width: 400px;
    margin-top: 60px !important;
    margin: auto;
}

.parabens {
  color: #FFFFFF;
  font-size: 51px;
  font-family: "Uniwars-W00-SemiBold";
  text-align: center;
  margin-top: 50px;
}

.txt-resultado {
  text-align: center;
  letter-spacing: 4.2px;
  color: #FFFFFF;
  font-size: 21px;
  font-family: "MetronicPro-Bold";
  margin-bottom: 50px;
}

.compartilhar {
  color: #EABB4E;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0px;
  font-family: "MetronicPro-Bold";
}

.row-resultado {
  align-items: center;
}

.redes-sociais {
  justify-content: center;
  gap: 20px;
}

@media (max-width: 989px) {
  .pergunta-quiz {
    width: 100%;
    text-align: center;
  }

  .img-logoquiz {
    margin: auto;
    display: block;
    margin-top: 20px !important;
  }

  .redes-sociais {
      gap: 5px;
  }

  .box-barra {
    width: 100%;
  }

  .col-foto {
    order: 1;
  }

  .parabens {
    font-size: 40px;
  }

  .img-quiz {
    width: 100%;
  } 
  
  .quiz {
      background-size: initial;
  }
}
</style>
  