<template>
  <div>
    <div
      v-if="fanpush && fanpush.fanpushAberto"
      data-anima="bottom"
    >
      <CountDownFanPush :data_fim_fanpush="fanpush.dataFimVotacao" />
    </div>

    <div class="bg-fanpush" id="votacaoFanPush">
      <b-row class="row-fanpush">
        <b-col cols="12" lg="6" class="boxSnapdragon">
          <img
            src="@/assets/img/snapdragon_vivo.png"
            class="selo"
            alt="Imagem do selo vivo fanpush"
          />
          <div class="txt-fanpush">
            <h3 class="textCenter">
              Vivo Fan Push by Snapdragon. <br />Votação que premia pilotos com
              um Push adicional.
            </h3>
            <p class="textCenter">
              Push é o botão utilizado para injetar potência no motor e aumentar
              a velocidade para atacar ou se defender durante a corrida.
            </p>
            <p class="textCenter" v-if="fanpush && !fanpush.usuarioVotou">
              Vote em 3 pilotos que você quer ajudar a vencer na próxima etapa.
              Os 6 mais bem votados receberão a potência de um Vivo Fan Push by
              Snapdragon.
            </p>
            <p class="textCenter" v-else style="font-weight: 600">
              Você já escolheu 3 pilotos! Lembre-se, os 6 mais bem votados
              receberão a potência de um Snapdragon Fan Push Vivo.
            </p>
          </div>
          <div v-if="fanpush && !fanpush.usuarioVotou && !fanpush.validado">
            <router-link
              v-if="fanpush && fanpush.fanpushAberto"
              to="/stockclub/votacao-fanpush"
              style="text-decoration: none; color: inherit"
            >
              <b-button class="btn-fanpush">
                <strong>VOTAR</strong> AGORA!</b-button
              ></router-link
            >
            <a v-else style="text-decoration: none; color: inherit" disabled>
              <b-button class="btn-fanpush" disabled>
                <strong>AGUARDE</strong>
              </b-button></a
            >
          </div>
          <div v-else class="escolhaFanPush">
            <b-row>
              <b-col
                cols="12"
                lg="4"
                v-for="(piloto, index) in pilotosVotados"
                :key="index"
              >
                <div class="pilotos-selecionados">
                  <p>{{ piloto.numero }}</p>
                  <img
                    :src="piloto.imagem"
                    alt="Imagem do piloto"
                    class="piloto-modal"
                  />
                </div>
                <p class="nome-modal">{{ piloto.nome }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col cols="12" lg="6" class="info-fanpush">
          <div class="beneficios">
            <img
              src="@/assets/img/100cvs.png"
              alt="100 cvs de potência extra"
            />
            <img
              src="@/assets/img/abertura.png"
              alt="abertura total da borboleta"
            />
            <img src="@/assets/img/pura.png" alt="pura adrenalina" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CountDownFanPush from "./CountDownFanPush.vue";
import { api } from "@/services.js";

export default {
  name: "VotacaoFanpush",
  data() {
    return {
      fanpushVotacao: null,
      pilotosVotados: [],
    };
  },
  components: {
    CountDownFanPush,
  },
  props: {
    fanpush: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    votacao_aberta() {
      if (this.fanpush) {
        const inicio = new Date(this.fanpush.dataInicioVotacao);
        const fim = new Date(this.fanpush.dataFimVotacao);
        const agora = new Date();

        return agora >= inicio && agora <= fim;
      }
    },

    fetchFanPush() {
      api
        .get(`/Fanpush`)
        .then((resp) => {
          this.fanpushVotacao = resp.data.fanpush;
          this.pilotosVotados = this.fanpushVotacao.listaPilotosVoto;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchFanPush();
  },
};
</script>

<style scoped>
.bg-fanpush {
  margin-top: 50px;
}
.boxSnapdragon {
  background-color: rgb(84 34 131 / 50%);
  padding: 50px 90px 90px;
  text-align: center;
}

.numPiloto {
  margin-bottom: 0px;
  color: #707070;
  font-size: 22px;
  font-family: "Uniwars-W00-Black";
}

.container-fanpush {
  margin-bottom: 60px;
}

.beneficios {
  display: grid;
  padding-top: 345px;
  padding-left: 50px;
}

.beneficios img {
  margin-bottom: 50px;
}

.info-fanpush h3 {
  margin-top: 50px;
  font-family: "Uniwars-W00-Light";
  font-size: 16px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.info-fanpush h3 strong {
  font-family: "Uniwars-W00-SemiBold";
}

.boxSnapdragon h3 {
  font-family: "MetronicPro-Bold";
  text-align: left;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--title-color);
  width: 87%;
  margin: 0 auto;
}

.txt-fanpush p {
  width: 87%;
  margin: 0 auto;
  font-family: "MetronicPro-Regular";
  text-align: left;
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--title-color);
  margin-top: 40px;
}

.btn-fanpush strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.btn-fanpush {
  font-family: "Uniwars-W00-Light";
  font-style: italic;
  font-size: 18px;
  background-color: transparent;
  border-color: white;
  color: white;
  border-radius: 6px;
  width: 280px;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.btn-fanpush:hover {
  background-color: var(--title-color) !important;
  border-color: var(--title-color) !important;
  color: var(--text-color) !important;
}

.bg-fanpush .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.selo {
  width: 350px;
}

.txt-fanpush {
  width: 90%;
  margin: 0 auto;
}

.borda-cinza {
  border-right: 1px solid #939393;
  padding-right: 20px;
}

.escolhaFanPush {
  justify-content: space-between;
  margin: 0 auto;
  width: 550px;
}
.itemFanPush {
  display: grid;
  justify-items: start;
}
.numFoto {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #fff;
}
p.txtNumPiloto {
  font-size: 45px;
  font-family: "Uniwars-W00-Heavy-Italick";

  letter-spacing: 0;
  color: #fff;
  margin: 0;
}
.boxNomeP {
  display: block;
  margin-top: 10px;
}
p.txtNomePiloto {
  font-size: 14px;
  font-family: "Uniwars-W00-Heavy-Italick";
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  margin: 0;
  text-align: left;
}

.numFoto img {
  margin-left: -24px;
}

.pilotos-selecionados {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: flex-end;
}

.pilotos-selecionados img {
  width: 80px;
  object-fit: contain;
}

.pilotos-selecionados p {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white !important;
  font-size: 45px;
  margin-top: 25px !important;
  margin-bottom: 0px !important;
  opacity: 0.8;
}

.nome-modal {
  color: var(--title-color);
  border-top: 1px solid #ffffff52;
  font-family: "UniwarsW00Regular";
  font-size: 12px;
  padding-top: 10px;
}

@media (max-width: 989px) {
  .pilotos-selecionados {
    /* display: grid; */
    margin-top: 20px;
    gap: 20px;
  }
  .pilotos-selecionados p {
    font-size: 32px;
  }

  .nome-modal {
    font-size: 15px;
  }

  .beneficios {
    padding-left: 0px;
    padding-top: 50px;
  }

  .boxSnapdragon {
    margin-top: 20px;
    padding: 50px 20px 20px;
  }

  .boxSnapdragon h3 {
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
  }
  .txt-fanpush p {
    font-size: 16px;
    line-height: 1.5;
  }
  .beneficios img {
    /* width: 100%; */
    height: 60px;
  }
  .escolhaFanPush {
    width: 100%;
  }
  .selo {
    width: 200px;
  }
}
a:disabled {
  pointer-events: none;
}
</style>