<template>
  <div>
    <div class="bg-maquinas">
      <b-container>
        <b-row>
          <b-col cols="12" lg="7"></b-col>
          <b-col cols="12" lg="5">
            <b-carousel
              id="carousel-2"
              v-model="slide"
              :interval="10000"
              controls
              indicators
              background="#e6c156"
              img-width="1024"
              img-height="580"
              style="text-shadow: 1px 1px 2px #333"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                v-for="item in maquinas"
                :key="item.idMaquina"
                img-src="https://emailimagesnb.s3.sa-east-1.amazonaws.com/guids/CABINET_739f4e8d235fc6f33869011f2d71c7ce4decb04f715d24f2d0f33c00c953e62f/images/bgcarrossel_g12.jpg"
              >
                <div class="img-carrossel">
                  <img
                    class="produto"
                    :src="item.imagens[0]"
                    alt="Imagem do produto máquina"
                  />
                  <img
                    class="logo-produto"
                    :src="item.imagens[1]"
                    alt="Logo do produto"
                  />
                </div>

                <h4>{{ item.titulo }}</h4>
                <div v-html="item.descricao" class="html" id="html"></div>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "Maquinas",
  data() {
    return {
      slide: 0,
      sliding: null,
      maquinas: [],
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    fetchMaquinas() {
      this.loading = true;
      api
        .get("/Maquina")
        .then((resp) => {
          this.maquinas = resp.data.maquinas;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchMaquinas();
  },
};
</script>

<style scoped>
.maquinas {
  margin-bottom: 50px;
}

.bg-maquinas .carousel {
  top: 200px;
}

.img-carrossel {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

</style>