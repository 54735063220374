<template>
  <div>
    <BaseMontadoras />
  </div>
</template>

<script>
import BaseMontadoras from "@/components/BaseMontadoras.vue";
export default {
  components: {
    BaseMontadoras,
  },
};
</script>

