<template>
  <section>
    <div class="logos-rodape" v-if="!loading">
      <b-container
        v-if="logos.montadoras.length && show_element.montadoras"
        data-anima="top"
      >
        <b-row class="divisao">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo"><strong>MONTADORAS</strong></h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="imgs-rodape">
              <div v-for="item in logos.montadoras" :key="item.imagem">
                <img :src="item.imagem" alt="Logo da montadora" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container
        v-if="logos.patrocinadores.length && show_element.patrocinadores"
        data-anima="top"
      >
        <b-row class="divisao">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 v-if="$route.params.categoria == 'formula-4'" class="titulo title-f4">
              <strong>{{ $t("title.patrocinadores1") }}</strong>
              {{ $t("title.patrocinadores2") }}
            </h2>
            <h2 v-else class="titulo">
              <strong>NOSSOS</strong> PATROCINADORES
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="imgs-rodape">
              <div
                v-for="(item, index) in logos.patrocinadores"
                :key="`${item.nome}-${index}`"
              >
                <img :src="item.imagem" alt="Logo do patrocinador" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container
        v-if="logos.fornecedores.length && show_element.fornecedores"
        data-anima="top"
      >
        <b-row class="divisao">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />

            <h2 v-if="$route.params.categoria == 'formula-4'" class="titulo title-f4">
              <strong>NOSSOS</strong> FORNECEDORES
            </h2>

            <h2 v-else class="titulo"><strong>NOSSOS</strong> FORNECEDORES</h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="imgs-rodape">
              <div
                v-for="(item, index) in logos.fornecedores"
                :key="`${item.nome}-${index}`"
              >
                <img :src="item.imagem" alt="Logo do fornecedor" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container
        v-if="logos.mediaPartners.length && show_element.mediaPartners"
        data-anima="top"
        class="media"
      >
        <b-row class="divisao">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />

            <h2 v-if="$route.params.categoria == 'formula-4'" class="titulo title-f4">
              <strong>MEDIA</strong> PARTNERS
            </h2>

            <h2 v-else class="titulo"><strong>MEDIA</strong> PARTNERS</h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="imgs-rodape">
              <div
                v-for="(item, index) in logos.mediaPartners"
                :key="`${item.nome}-${index}`"
              >
                <img :src="item.imagemPartner" alt="Logo da media partners" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="loading" class="logos-rodape container loading">
      <h2 class="titulo" style="color: transparent">.</h2>

      <div class="logos">
        <b-skeleton type="button"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import { EventBus } from "@/main.js";

export default {
  name: "LogosHome",
  props: {
    show_element: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      logos: {
        montadoras: [],
        fornecedores: [],
        patrocinadores: [],
        mediaPartners: [],
      },
      loading: false,
    };
  },
  methods: {
    fetchLogos(categoria_id) {
      if (!categoria_id) {
        return false;
      }
      this.logos = {
        montadoras: [],
        fornecedores: [],
        patrocinadores: [],
        mediaPartners: [],
      };
      this.loading = true;
      api
        .get(`/Footer/categoria/${categoria_id}`)
        .then((resp) => {
          this.logos = resp.data;

          this.logos.montadoras;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchLogos(mapCategorias[this.$route.params.categoria || "proseries"]);
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.fetchLogos(mapCategorias[data || "proseries"]);
    });
  },
};
</script>

<style scoped>

.title-f4 strong {
  font-family: "Venera-900" !important;
}

.title-f4 {
  font-family: "Venera-300" !important;
}

.logos-rodape {
  margin-top: 30px;
}
.loading {
  padding: 220px 0;
}
.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 120px;
}
@media (max-width: 989px) {
  .logos-rodape {
    margin-top: 30px;
  }
  .logos-rodape .container + .container {
    padding-top: 40px;
  }
}
</style>